import axios from "axios";
import { set, get, clear } from "./storage";
import localforage from 'localforage';

// import {get_instanceID_info} from './cms/api'

const instance = axios.create({ baseURL: process.env.REACT_APP_URL_WS });
const instanceCms = axios.create({ baseURL: process.env.REACT_APP_URL_CMS });

const mapRegisterError = message => {
  if (message.error) {
    return "Identifiant déjà utilisé";
  }
  return "Erreur d'authentification";
};

const mapAuthError = message => {
  // if (message.error === "not_email_active") {
  //   return `Ton compte n'a pas encore été activé - Le message d'activation a été envoyé à l'adresse ${
  //     message.username
  //   }`;
  if (message.error === "not_activated" && message.is_active_sanady_prof) {
    return `Votre demande d'inscription est transmise. Vous pouvez vous connectez à votre compte une fois validé par un responsable. Merci.`;
  } else if (message.error === "not_activated") {
    return `Ton compte n'a pas encore été activé - Le message d'activation a été envoyé à l'adresse ${message.parent_email}`;
  } else if (message.error === "no_subscription") {
    return "Votre abonnement a expiré";
  } else if (message.error === "not_email_active") {
    return [
      `Ton compte n'a pas encore été activé - Le message d'activation a été envoyé à l'adresse ${message.email}`,
      "mail_not_yet_verified"
    ];
  } else if (message.error === "not_phone_active") {
    return [
      `Ton compte n'a pas encore été activé - Le code d'activation a été envoyé au numéro ${message.phone}`,
      "phone_not_yet_verified"
    ];
  } else {
    return "Username or Password incorrect";
  }
  //return "Erreur d'authentification";
};

//Global register api
export const register_global_api = object => {
  return instance
    .post(`global_register_api`, object)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapRegisterError(err.response.status));
      }
      throw err;
    });
}

// Parent API
export const registerParent = object => {
  return instance
    .post("/api/koolskools/v1/account/parent_registration/", object)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapRegisterError(err.response.status));
      }
      throw err;
    });
};

// School API
export const registerSchool = registerSchool => {
  return instance
    .post("/api/koolskools/v1/account/school_registration/", registerSchool)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapRegisterError(err.response.status));
      }
      throw err;
    });
};

// Profesor API
export const registerProfessor = registerProfessor => {
  return instance
    .post(
      "/api/koolskools/v1/account/professor_registration/",
      registerProfessor
    )
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapRegisterError(err.response.status));
      }
      throw err;
    });
};

// Parent API

// End parent api

export const levelGroups = () => {
  var data = {};
  const user = get('session_user');
  const school = get('school');
  if(user && (user.profile && user.profile.is_program_system || user.profile.is_sanady_assistant || school && school.project_id || user.is_tarl_professor)){
    data.is_program_system = "true";
  }
  return instance
    .get(`/api/koolskools/v1/level_groups/`,{
      params: data
    })
    .then(response => {
      var levels_groups = response.data.level_groups;
      if(data.is_program_system){
        let tarl_cycle = undefined;
        levels_groups = levels_groups.map(lg => {
          if(lg.is_tarl){
            lg.name = "Primaire";
            tarl_cycle = lg;
          }
          return lg;
        }).filter(lg => lg.num > "1" && !lg.is_tarl);
        if(tarl_cycle){
          levels_groups.unshift(tarl_cycle);
        }
      }
      else{
        levels_groups = levels_groups.filter(lg => !lg.is_tarl);
      }
      set("level_groups", levels_groups);
      var event = document.createEvent('Event');

      // Nomme l'événement 'build'.
      event.initEvent('store_level_groups', true, true);
      window.dispatchEvent(event)
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const register = register => {
  return instance
    .post("/api/koolskools/v1/account/child_register/", register)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapRegisterError(err.response.data));
      }
      throw err;
    });
};

export const child_api_courses = (classId,data) => {
  const user = get('session_user');
  return instance  
      .get(`/child_courses/${classId}/`, {
        headers: {Authorization: "Bearer " + user.token},
        params: data
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {} throw err;});
};

export const login = login => {
  return instance
    .post("/api/koolskools/v1/account/login_with_token/", login)
    .then(response => {
      
      var lev = get("level_groups");
      var current_language = get("current_language"),
          device_token = get("device_token");
      clear();
      localforage.clear()
      if(global.ws_notification)
        global.ws_notification.close();
      set("device_token", device_token);
      set('topics', {});
      set("level_groups", lev);
      set("current_language", current_language);
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.data));
      }
      throw err;
    });
};

export const loginWithClasse = (token, obj) => {
  return instance
    .post("/ajax/link_std_sc", obj, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.data));
      }
      throw err;
    });
};

export const resendActivationData = data => {
  return instance
    .post("/resend_activation_data", data)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
      }
      throw err;
    });
};

export const forgotPassword = password => {
  return instance
    .post("/api/koolskools/v1/account/forgot_password/", password)
    .then(response => {
      return response.data;
    });
};

export const textbook = (token, classId, subjectId, data) => {
  return instance
    .get(`/api/tasks/${classId}/${subjectId}/`, {
      headers: { Authorization: "Bearer " + token},
      params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};
export const textbook_correction_viewed = (data) => {
  const user = get("session_user");
  return instance
    .get(`/api/tasks/correction_viewed/`, {
      headers: { Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const textbook_count = (classId, object) => {
  const user = get("session_user");
  return instance
    .get(`/api/count_tasks/${classId}/`, {
      headers: { Authorization: "Bearer " + user.token },
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // if (err.response) {
      //   throw new Error(mapAuthError(err.response.status));
      // }
      throw err;
    });
};

export const courses_count = (classId, object) => {
  const user = get("session_user");
  return instance
    .get(`/api/count_courses/${classId}/`, {
      headers: { Authorization: "Bearer " + user.token },
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // if (err.response) {
      //   throw new Error(mapAuthError(err.response.status));
      // }
      throw err;
    });
};
export const live_count = (classId, object) => {
  const user = get("session_user");
  return instance
    .get(`/api/count_live/${classId}/`, {
      headers: { Authorization: "Bearer " + user.token },
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // if (err.response) {
      //   throw new Error(mapAuthError(err.response.status));
      // }
      throw err;
    });
};
export const textbook_exercises = (task_id) => {
  const user = get("session_user");
  return instance
    .get(`/api/exercises_task/${task_id}/`, {
      headers: { Authorization: "Bearer " + user.token }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // if (err.response) {
      //   throw new Error(mapAuthError(err.response.status));
      // }
      throw err;
    });
};

export const challenge_exercises = (challene_id, data) => {
  const user = get("session_user");
  return instance
    .get(`/api/exercises_challenge/${challene_id}/`, {
      headers: { Authorization: "Bearer " + user.token },
      params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // if (err.response) {
      //   throw new Error(mapAuthError(err.response.status));
      // }
      throw err;
    });
};

export const textbookValidate = (data) => {
  const user = get("session_user");
  return instance
    .post(
      `/tasks/${data.task_id}/toggle_validate`,
      data,
      {
        headers: {
          Authorization: "Bearer " + user.token
        }
      }
    )
    .then(response => {
      return response.data;
    });
    // .catch(err => {
    //   if (err.response) {
    //     throw new Error(mapAuthError(err.response.status));
    //   }
    //   throw err;
    // });
};
export const attach_correction_files = (data) => {
  const user = get("session_user");
  return instance
    .post(
      `/tasks/${data.task_id}/correction_files`,
      data,
      {
        headers: {
          Authorization: "Bearer " + user.token
        }
      }
    )
    .then(response => {
      return response.data;
    });
    // .catch(err => {
    //   if (err.response) {
    //     throw new Error(mapAuthError(err.response.status));
    //   }
    //   throw err;
    // });
};

export const challenges = token => {
  return instance
    .get(`/api/challenges/`, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};
export const finished_challenges_api_pagination = object => {
  var user = get("session_user");
  return instance
    .get(`/api/finished_challenges_api_pagination/`, {
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const koolskools_activities = (token, object) => {
  return instance
    .get(`/api/koolskools_activities/`, {
      headers: {
        Authorization: "Bearer " + token
      },
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const koolskools_exams = (token, object) => {
  return instance
    .get(`/api/koolskools_exams/`, {
      headers: {
        Authorization: "Bearer " + token
      },
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const koolskools_school_books = (token, object) => {
  return instance
    .get(`/api/koolskools_school_books/`, {
      headers: {
        Authorization: "Bearer " + token
      },
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const refresh_ticket = (token, object) => {
  return instance
    .post(`/cas_kls/refresh_ticket/`,object, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};
export const get_edulib_catalog = (token, establishment_account_id) => {
  return instance
    .get(`https://staging.edulib.fr/api/v4/establishment_accounts/${establishment_account_id}/catalog`, {
      headers: {
        Authorization: "Bearer " + token
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};
export const get_catalog_from_koolskools = (provider) => {
  var user = get("session_user");
  return instance
    .get(`api/scholar_books_catalogue`, {
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: {provider: provider}
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const user_activity_like = (token, object) => {
  return instance
    .get(`/api/koolskools_user_activity_like/`, {
      headers: {
        Authorization: "Bearer " + token
      },
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const get_scholar_year_object = (token, object) => {
  return instance
    .get(`/api/get_scholar_year_object/`, {
      headers: {
        Authorization: "Bearer " + token
      },
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const challengeListExercice = (token, type) => {
  return instance
    .get(`/challenges_api_exercises/${type}/`, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const challengeXblock = (token, course, exercise, data) => {
  return instance
    .get(`/courses/${course}/xblock/${exercise}/view/student_view`, {
      headers: {
        Authorization: "Bearer " + token
      },
      params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const exam_stats = (object) => {
  var user = get("session_user");
  return instanceCms
    .get(`/api/exam/stats`, {
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

// export const submitXblock = (token, course, exercise, data) => {
//   return instance
//     .post(`/courses/${course}/xblock/${exercise}/handler/done_common`, data ,{
//       headers: {
//         Authorization: "Bearer " + token
//       }
//     })
//     .then(response => {
//       return response.data;
//     })
//     .catch(err => {
//       if (err.response) {
//         throw new Error(mapAuthError(err.response.status));
//       }
//       throw err;
//     });
// };

export const levels = token => {
  return instance
    .get(`/user_levels/`, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      set('levels', response.data.user_levels);
      return response.data;
      
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const changeLevels = (token, idLevel) => {
  return instance
    .post(`/change_current_level/`, idLevel, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      var session_user = get("session_user");
      session_user.current_level = response.data.current_level;
      set('session_user', session_user);
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const editProfile = (token, username, obj) => {
  return instance
    .post(`/api/koolskools/v1/child/${username}/`, obj, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const avatar = token => {
  return instance
    .get(`/api/koolskools/v1/avatars/?page_size/`, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const changeAvatar = (token, slug) => {
  return instance
    .post("/change_avatar/", slug, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const changePassword = (token, obj) => {
  return instance
    .post("/api/koolskools/changepassword/", obj, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const friends_api_list = classe_id => {
  const user = get("session_user");
  return instance
    .get(`/friends_api_handler/${classe_id}/`, {
      headers: {
        Authorization: "Bearer " + user.token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      // if (err.response) {
      //   clear();
      // }
      throw err;
    });
};

export const listFriends = token => {
  return instance
    .get(`/list_friends_handler?username_name`, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        clear();
        localforage.clear()
        if(global.ws_notification)
          global.ws_notification.close();
      }
      throw err;
    });
};
export const nbr_invitation = token => {
  return instance
    .get(`nbr_invitation_handler/?username_name`, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        clear();
        localforage.clear()
        if(global.ws_notification)
          global.ws_notification.close();
      }
      throw err;
    });
};

export const CRUDFriend = (token, friend) => {
  return instance
    .post(`/list_friends/store/`, friend, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const get_posts = object => {
  const user = get("session_user");
  return instance
    .get(`/exercises_posts/`, {
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err.response;
    });
};

export const post_post = (object) => {
  const user = get("session_user");
  return instance
    .post(`/exercises_posts/`, object, {
      headers: {
        Authorization: "Bearer " + user.token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err.response;
    });
};

export const challengesFriendsScore = token => {
  return instance
    .get(`/stats_challenge_score/`, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const statistics = (token, data) => {
  return instance
    .get(`/get_exercice_level/`, {
      headers: {
        Authorization: "Bearer " + token
      },
      params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const uploadImage = (file) => {
  const user = get("session_user");
  return instance
    .post("/upload_image/", file, {
      headers: {
        Authorization: "Bearer " + user.token,
        "Content-Type": "multipart/form-data"
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const listImage = (token, data) => {
  return instance
    .get(`/list_api_images/`, {
      headers: {
        Authorization: "Bearer " + token
      },
      params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      clear();
      localforage.clear()
      if(global.ws_notification)
        global.ws_notification.close();
    });
};
export const lessons_list = (token, data) => {
  return instance
    .get(`/lessons_list_api/`, {
      headers: {
        Authorization: "Bearer " + token
      },
      params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      clear();
      localforage.clear()
      if(global.ws_notification)
        global.ws_notification.close();
    });
};


export const chaptersList = (levelId, subjectId) => {
  const user = get("session_user");
  return instance
    .get(`/api/chapters/${levelId}/${subjectId}`, {
      headers: {
        Authorization: "Bearer " + user.token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        //throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const chapterItem = (token, chapterId) => {
  return instance
    .get(`/api/courses/${chapterId}`, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

export const exerciseOfStage = stageId => {
  const user = get("session_user");
  return instance
    .get(`/api/exercises_of_stage/${stageId}`, {
      headers: {
        Authorization: "Bearer " + user.token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        // throw new Error(mapAuthError(err.response.status));
      }
      throw err;
    });
};

/***************** activate_account_api ******************/

export const activate_account_api = key => {
  return instance
    .post(`/activate/${key}`)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
      }
      throw err;
    });
};

export const professor_activate_account_api = key => {
  return instance
    .get(`/sc-pr/activate/${key}`)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      return err.response;
    });
};

export const check_username_connected = () => {
  const user = get("session_user");
  return instance
    .post(`/check_user_exist`, {"username": user.profile.username}, {
      headers: { Authorization: "Bearer " + user.token }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
      }
      throw err;
    });
};

export const change_password_api = data => {
  const user = get("session_user");
  return instance
    .post(`/api/koolskools/changepassword/`, data, {
      headers: { Authorization: "Bearer " + user.token }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
      }
      throw err;
    });
};
