 
const registerServiceWorker = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register(process.env.PUBLIC_URL + "/firebase-messaging-sw.js")
        .then(function(registration) {
          // console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function(err) {
          console.log("Service worker registration failed, error:", err);
        });
        navigator.serviceWorker.onmessage = (msg) => {
          console.log("111")
          if(msg.data.firebaseMessaging){
            let payload = msg.data.firebaseMessaging.payload;
            if(payload.data.type == 'new_student_recovery'){
                if(!payload.data.to_admin){
                  // EventRegister.emit('fetch_localisations', remoteMessage.data)
                  var event = new CustomEvent("fetch_localisations", {
                    "detail": payload.data
                  });
                
                  document.dispatchEvent(event);
                  // var event = document.createEvent('Event');
          
                  // event.initEvent('fetch_localisations', true, true);
                  // window.dispatchEvent(event)
                }
            }
          }
        }

        // navigator.serviceWorker.onBackgroundMessage = msg => {
        //   console.log(msg)
        // }

    }
  };
  
  export { registerServiceWorker };